exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accommodation-index-tsx": () => import("./../../../src/pages/accommodation/index.tsx" /* webpackChunkName: "component---src-pages-accommodation-index-tsx" */),
  "component---src-pages-faqs-index-tsx": () => import("./../../../src/pages/faqs/index.tsx" /* webpackChunkName: "component---src-pages-faqs-index-tsx" */),
  "component---src-pages-games-index-tsx": () => import("./../../../src/pages/games/index.tsx" /* webpackChunkName: "component---src-pages-games-index-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-index-tsx": () => import("./../../../src/pages/menu/index.tsx" /* webpackChunkName: "component---src-pages-menu-index-tsx" */),
  "component---src-pages-photos-index-tsx": () => import("./../../../src/pages/photos/index.tsx" /* webpackChunkName: "component---src-pages-photos-index-tsx" */),
  "component---src-pages-schedule-tsx": () => import("./../../../src/pages/schedule.tsx" /* webpackChunkName: "component---src-pages-schedule-tsx" */),
  "component---src-pages-seating-index-tsx": () => import("./../../../src/pages/seating/index.tsx" /* webpackChunkName: "component---src-pages-seating-index-tsx" */),
  "component---src-pages-venue-js": () => import("./../../../src/pages/venue.js" /* webpackChunkName: "component---src-pages-venue-js" */)
}

